<div *ngIf="showFooter" class="full-wrap">
  <div id="footer">
  <div class="footer-sidebar-1">
  <p>Text and photos &#169;2025 Great Plant Picks/Elisabeth Carey Miller Garden except where otherwise noted<br>
  Funded by the <a href="https://www.millercharitablefoundation.org/">Pendleton and Elisabeth Carey Miller Charitable Foundation</a>, <a href="https://www.seattletimes.com/">The Seattle Times</a>, and Individual Donors<br>
    Administered by the <a href="http://www.millergarden.org/">Elisabeth Carey Miller Botanical Garden</a></p>
</div>
	  <div class="footer-sidebar-2">

		  </div>
  </div><!-- end footer -->
</div>
