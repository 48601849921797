import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { PlantService } from '../services/plant.service';
import { PlantLists } from '../models/PlantLists.model';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-searchnav',
  templateUrl: './searchnav.component.html',
  styleUrls: ['./searchnav.component.scss']
})

export class SearchnavComponent implements OnInit {
  static selectedList = 'pollinators';
  get selectedList(): string {
    return SearchnavComponent.selectedList;
  }


  @ViewChild('searchButton', { static: true }) searchButtonElement: ElementRef;
  options = [];
  basicSearchTerms = '';
  basicSearchTermsInput = new FormControl();
  public allPlantLists: PlantLists;
  keyword = 'common_name';
  placeholder = 'Enter botanical or common name';


  constructor(private router: Router,
              private plantService: PlantService,
              public  authService: AuthService,
              private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  gotoHome() { this.router.navigateByUrl('/'); }
  gotoAbout() { this.router.navigateByUrl('/about'); }
  gotoSearch() { this.router.navigateByUrl('/searchnav'); }
  gotoAdvSearch() { this.router.navigateByUrl('/search/advanced'); }
  gotoResources() { this.router.navigateByUrl('/resources'); }
  gotoPress() { this.router.navigateByUrl('/press'); }
  gotoContact() { this.router.navigateByUrl('/contact'); }
  gotoGlossary() { this.router.navigateByUrl('/resources/glossary'); }
  gotoGlossaryDeer() { this.router.navigate(['/resources/glossary'], {fragment: 'deer_resistance'}); }
  gotoGlossaryWater() { this.router.navigate(['/resources/glossary'], {fragment: 'watering_drought_tolerant'}); }
  gotoGlossaryShade() { this.router.navigate(['/resources/glossary'], {fragment: 'dappled_shade'}); }
  gotoPlantNotListed() { this.router.navigateByUrl('/search/plantNotListed'); }

  gotoListByTheme(category, inTheme) {
    this.plantService.searchCommonOptions = '';
    this.plantService.advancedSearchOptions = '&theme=' + encodeURI(inTheme);
    this.plantService.searchCategory = category;
    this.router.navigateByUrl('/search/results');
  }
  gotoListByType(inType) {
    this.plantService.searchCommonOptions = '';
    this.plantService.searchCategory = '';
    this.plantService.advancedSearchOptions = '&plantType=' + encodeURI(inType);
    this.router.navigateByUrl('/search/results');
  }
  gotoListByOptions(...args: any[]) {
    let searchString = '';
    this.plantService.searchCategory = args[0];
    for (let i = 1; i < args.length; i = i + 2) {
      searchString += '&' + args[i] + '=' + encodeURI(args[i + 1]);
    }
    this.plantService.searchCommonOptions = '';
    this.plantService.advancedSearchOptions = searchString;
    this.router.navigateByUrl('/search/results');
  }
  basicSearch() {
    this.plantService.advancedSearchOptions = '';
    this.plantService.searchCategory = '';
    this.plantService.searchCommonOptions = encodeURI(this.basicSearchTerms);
    this.router.navigateByUrl('/search/results');
  }

  basicSearchMobile() {
    this.plantService.searchCommonOptions = encodeURI(this.basicSearchTermsInput.value);
    this.plantService.advancedSearchOptions = '';
    this.plantService.searchCategory = '';
    this.router.navigateByUrl('/search/results');
  }

  navigate(destination) {
    SearchnavComponent.selectedList = destination;
  }

  highlightMe(selectedItem: string) {
    if (selectedItem === SearchnavComponent.selectedList) {
      return {'background-color': '#d3ecb9', color: '#000000'};
    } else {
      return {'background-color': '#ffffff', color: '#000000'};
    }
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
    this.basicSearchTerms = val;
    this.plantService.advancedSearchOptions = '';
    this.plantService.searchCategory = '';
    this.plantService.searchCommonOptions = this.basicSearchTerms;
    if (val.length > 0) {
      this.plantService.getPlantAutoComplete(this.authService.isLoggedIn())
        .subscribe((res) => {
          this.options = res.plants;
        });
    } else {
      this.options = [];
    }
  }

  selectEvent(item) {
    // do something with selected item
    setTimeout(() => {
      this.searchButtonElement.nativeElement.click();
    }, 500);
    this.options = [];
  }

  onFocused(e) {
    // do something when input is focused
  }

}
