<div class="home-image" style="background-image: url(../../assets/plants/tulipa-spring-green.jpg)">	  <h1>Press</h1>
</div> 
<div class="wrapper">

     <div class="breadcrumbs">
<ul>
      <li><a [routerLink]="" id="current" (click)="gotoPress()">Press</a></li>
	  <li><a [routerLink]="" (click)="gotoPoster()">Posters</a></li>
      <li><a [routerLink]="" (click)="gotoTerms()">Image &amp; Logo Use Guidelines</a></li>
    </ul>
  </div><!-- end breadcrumbs -->
<div class="text-wrap">
  <h2>Outstanding plant selections for the maritime Pacific Northwest</h2>
  <h1 style="margin-bottom:0;">Pollinator Paradise</h1>
	<p style="text-align:center; font-size:18px; padding-bottom:12px;"><strong>Bees &#x2740; Butterflies &#x2740; Hummingbirds</strong></p>
	<p>Plant pollinators, such as bees, butterflies, and hummingbirds, play a vital role in maintaining the delicate balance of our ecosystems. By promoting plant pollinators, we are not only enhancing biodiverity but also supporting the health of our environment. Creating pollinator-friendly spaces with trees, shrubs, and perennials can make a significant impact on the survival of these essential species.</p>

<p>On the <a href="https://greatplantpicks.org/searchnav">Helpful Plant Lists</a> page, you will find outstanding pollinator plants that will grow well and bring beauty to gardens, landscapes and containers. You will discover the wide variety of plants that have been vetted by Pacific Northwest green industry experts!</p>
  
    <p>If you are a journalist, garden writer, or newsletter editor, Great Plant Picks can supply <a [routerLink]="" (click)="gotoTerms()">photos</a> and information for more than 1,000 unbeatable garden plants for the maritime Pacific Northwest. For more information on this year's theme, "POLLINATOR PARADISE", or other Great Plant Picks contact Rick Peterson at <a href="mailto:rickp@millergarden.org">rickp@millergarden.org</a>.</p>

    <p>Are you, your publication, your garden group, or nursery business on the GPP email list? If not, please send your email address to <a href="mailto:rickp@millergarden.org">rickp@millergarden.org</a> for the latest press releases and GPP news information.</p>
    
  <img class="aligncenter size-full" alt="" title="Scent-sational Plants Poster" src="../assets/posters/2025-gpp-poster.jpg">
</div><!-- .text-wrap -->
</div><!-- end wrapper -->
